import ActionType from './action-type'
import {Action} from './actions'
import {isEqual} from 'lodash'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'

import {ConfigFetchingStatus, ConfigReduxState, DEFAULT_CONFIG_STATE} from './state'
import produce from 'immer'

const configReducer = produce(
    (draft: ConfigReduxState = DEFAULT_CONFIG_STATE, action: Action | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.REQUEST_CONFIG:
                const flagChanged = draft.fetchingStatus === ConfigFetchingStatus.NEEDS_RESETTING

                draft.splunkDashboardUrl = undefined
                if (draft.features?.length !== 0) {
                    draft.features = []
                }
                draft.tenantDisplayName = ''
                draft.fetchingStatus = flagChanged
                    ? ConfigFetchingStatus.REREQUESTING
                    : ConfigFetchingStatus.REQUESTING
                draft.oauthClientIds = undefined
                break
            case ActionType.SET_LICENSE_METRICS_ONBOARD_ENGAGEMENT:
                if (!draft.fleetConfig.LicenseState) {
                    draft.fleetConfig.LicenseState = {}
                }
                draft.fleetConfig.LicenseState['License:Metrics:OnboardEngagement'] =
                    action.payload ?? undefined
                break
            case ActionType.SET_LICENSE_INCIDENTS:
                if (!draft.fleetConfig.LicenseState) {
                    draft.fleetConfig.LicenseState = {}
                }
                draft.fleetConfig.LicenseState['License:Incidents'] = action.payload
                break
            case ActionType.SET_LICENSE_ENFORCEMENT_NETWORKS:
                if (!draft.fleetConfig.LicenseState) {
                    draft.fleetConfig.LicenseState = {}
                }
                draft.fleetConfig.LicenseState['License:Enforcement:Networks'] =
                    action.payload ?? undefined
                draft.acEnabled = action.payload?.value?.enabled ?? false
                break
            case ActionType.SET_LICENSE_ENFORCEMENT_REMOVABLES:
                if (!draft.fleetConfig.LicenseState) {
                    draft.fleetConfig.LicenseState = {}
                }
                draft.fleetConfig.LicenseState['License:Enforcement:Removables'] =
                    action.payload ?? undefined
                break
            case ActionType.RECEIVE_CONFIG:
                draft.splunkDashboardUrl = action.payload.splunkDashboardUrl
                if (!isEqual(draft.features, action.payload.features)) {
                    draft.features = action.payload.features!
                }
                draft.tenantDisplayName = action.payload.tenantDisplayName
                draft.fetchingStatus = ConfigFetchingStatus.INITIALISED
                if (!isEqual(draft.oauthClientIds, action.payload.oauthClientIds)) {
                    draft.oauthClientIds = action.payload.oauthClientIds
                }
                break
            case ActionType.SET_ENABLE_REPORTS_PAGE:
                draft.fleetConfig.reports = action.payload
                break
            case ActionType.SET_ENABLE_NETWORK_ASSETS_PAGE:
                draft.fleetConfig.networkAssets = action.payload
                break
            case ActionType.SET_ENABLE_USB_INVENTORY_PAGE:
                draft.fleetConfig.usbInventory = action.payload
                break
            case ActionType.SET_ENABLE_SOFTWARE_INVENTORY_PAGE:
                draft.fleetConfig.softwareInventory = action.payload
                break
            case ActionType.SET_ENABLE_NMEA_INVENTORY_PAGE:
                draft.fleetConfig.nmeaInventory = action.payload
                break
            case ActionType.SET_INCIDENT_REOPEN_DURATION:
                draft.fleetConfig.incidentReopenDuration = action.payload
                break
            case ActionType.SET_GFC_FLAG:
                draft.gfcFlag = action.payload
                break
            case ActionType.SET_CUSTOM_LOGO_FLAG:
                draft.customLogoFlag = action.payload
                break
            case ActionType.SET_USER_GUIDING:
                draft.userGuidingConfig = action.payload
                break
            case SessionActionType.LOGGING_OUT:
                if (draft.features?.length != 0) {
                    draft.features = []
                }
                const emptyValue = {
                    google: '',
                    microsoft: '',
                }
                if (!isEqual(draft.oauthClientIds, emptyValue)) {
                    draft.oauthClientIds = emptyValue
                }
                draft.fetchingStatus = ConfigFetchingStatus.NEEDS_RESETTING
                break
            /* istanbul ignore next */
            default:
                break
        }

        return draft
    },
)
export default configReducer
