/* eslint-disable @typescript-eslint/no-explicit-any */
import {AnyAction, applyMiddleware, createStore, Reducer, Store} from 'redux'
import {createBrowserHistory, History} from 'history'
import {default as thunkMiddleware, ThunkMiddleware} from 'redux-thunk'
import composeEnhancers from './compose-enhancers'
import {routerMiddleware} from 'connected-react-router'

import {Api} from '../api/Api'
import {loadState, saveState} from './local-storage'
import AppState, {toPersistableAppState} from './types/app-state'
import PersistableAppState from './types/persistable-app-state'
import {INITIAL_STORE} from './types/initial-store'
import rootReducer from './root-reducer'

interface AsyncReducers {
    [key: string]: Reducer
}

export const history: History = createBrowserHistory()
export let api: Api

export default function configureStore(): Store<AppState> {
    api = new Api()

    // If it's a jest test don't try to use local storage
    const persistedStore: PersistableAppState | undefined =
        import.meta.env.JEST_WORKER_ID == undefined ? loadState() : undefined

    const preloadedState = {...INITIAL_STORE, ...persistedStore}

    const enhancer = composeEnhancers(
        applyMiddleware(
            routerMiddleware(history),
            thunkMiddleware.withExtraArgument(api) as ThunkMiddleware<AppState, AnyAction, Api>,
        ),
    )

    const store = createStore<any, AnyAction, any, {}>(
        rootReducer(history),
        preloadedState,
        enhancer,
    )

    // configure persistable state
    store.subscribe(() => saveState(toPersistableAppState(store.getState())))
    api.init(store)

    if (persistedStore != undefined) {
        if (persistedStore.auth.authToken == undefined) {
            api.initWs()
        }
    }

    return store
}

const asyncReducers: AsyncReducers = {}
export const injectReducer = (
    key: string,
    asyncReducer: Reducer,
    store: Store<any, AnyAction>,
): void => {
    asyncReducers[key] = asyncReducer
    store.replaceReducer(rootReducer(history, asyncReducers))
}
