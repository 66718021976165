import {Dispatch, useContext, useEffect} from 'react'
import {IncidentsWidgetContext} from './incidents-widget-context'
import {UseIncidentsWidgetReportResult} from './use-incidents-widget-output'
import * as ActionCreators from './state/action-creators'
import {
    isActiveLocation,
    Location,
    LocationIdType,
} from '../../../../../store/state/locations/state'
import {AllActions} from './state/actions'
import {REST} from '../../../../..'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {
    activeLocationSelector,
    locationMapSelector,
    locationsSelector,
} from '../../../../../store/state/locations/selectors'
import LoadingState from '../../../../../values/loading-state-enum'
import {transformTypesCountsToArray} from '../../../../incidents-report/contexts/data-helper'
import {
    DEFAULT_ASSIGNMENTS_COUNTS,
    DEFAULT_INCIDENTS_COUNTS,
    DEFAULT_VESSELS_PERFORMANCE_TYPE,
    IncidentAssignmentsCounts,
    IncidentAvgPerformanceType,
    IncidentCountsType,
    IncidentTrendsType,
    IncidentVesselsPerformanceType,
} from '../../../../incidents-report/contexts/types/incidents-report-output'
import {INCIDENTS_ENDPOINT} from '../../../../vessels-beta/contexts/types/vessels-beta-endpoints'
import {DefaultPeriodOptions} from '../../period-filter-type-dashboard'
import {IncidentWidgetType} from './types/incident-widget-type'
import {filteredVesselIds} from './reselector/location-selection-number-reselector'
import {AxiosResponse} from 'axios'
import {formattedLocationsBlockSet} from '../../../../../utils/Utils'
import {DEFAULT_INCIDENTS_WIDGET_FILTER} from './types/default-incidents-widget-state'
import {nodesSelector} from '../../../../../store/state/nodes/selectors'
import {WidgetOutputModel} from '../widget.model'
import {warn} from '../../../../../helpers/logging'
import {QueryCancellation} from '../../../../../websocket/Queries'
import {api} from '../../../../../store/configure-store'
import * as uuid from 'uuid'
import {QuerySpecification} from '../../../../../api/Api'
import {ActionType} from './state/action-type'

const USER_PREFS_URL = '/api/v1/userPreferences/incidentsReport'

export function useIncidentsWidget(): UseIncidentsWidgetReportResult {
    const {state, dispatch} = useContext(IncidentsWidgetContext)
    if (state == undefined || dispatch == undefined) {
        throw new Error('useIncidentsWidget must be used within a IncidentsWidgetContext')
    }
    const allLocations = useTypedSelector(locationsSelector)
    const activeLocation = useTypedSelector(activeLocationSelector)
    const locationMap = useTypedSelector(locationMapSelector)
    const nodes = useTypedSelector(nodesSelector)

    function setPeriodWidgetOnDashboard(periodWidgetOnDashboard: DefaultPeriodOptions): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        ActionCreators.setPeriodWidgetOnDashboard(
            periodWidgetOnDashboard,
            dispatch,
            state.incidentWidgetFilter,
        )
    }

    function displayIncidentWidgetOnDashboard(incidentWidgetOnDashboard: IncidentWidgetType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        ActionCreators.displayIncidentWidgetOnDashboard(
            incidentWidgetOnDashboard,
            dispatch,
            state.incidentWidgetFilter,
        )
    }

    function setWidgetOnDashboardData(
        periodWidgetOnDashboard: DefaultPeriodOptions,
        incidentsWidgetOnDashboard: IncidentWidgetType,
        locations?: LocationIdType,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setFilter())
        const formattedLocation = locations ? new Set([locations]) : undefined
        getDataForDashboard(
            dispatch,
            periodWidgetOnDashboard,
            formattedLocation,
            state.incidentWidgetFilter.searchVesselTagTerm,
            allLocations,
            incidentsWidgetOnDashboard,
        )
    }

    const QUERY_PATH = 'incidentsOverview'
    const QUERY_SCHEDULE = '1m'

    function incidentsOverviewQuery(activeLocation?: LocationIdType[]): QuerySpecification {
        return {
            path: QUERY_PATH,
            localId: `${QUERY_PATH}-${uuid.v4()}`,
            schedule: QUERY_SCHEDULE,
            params: {
                locations: activeLocation,
            },
        }
    }

    function registerIncidentsOverviewQuery(locations?: LocationIdType): QueryCancellation {
        if (!dispatch) {
            warn('dispatch is not defined')
            return () => {
                api.cancelQuery({localId: query.localId})
            }
        }

        dispatch(ActionCreators.setFilter())

        const formattedLocation = locations ? new Set([locations]) : undefined
        const filteredVessels = filteredVesselIds(
            allLocations,
            formattedLocation,
            formattedLocation ? [] : state.incidentWidgetFilter.searchVesselTagTerm ?? [],
        )
        const query: QuerySpecification = incidentsOverviewQuery(
            getFormattedFilteredVessels(allLocations, filteredVessels),
        )
        api.newQuery(query, (data) => {
            dispatch(ActionCreators.receiveIncidentsOverview(data))
        })

        return () => {
            api.cancelQuery({localId: query.localId})
        }
    }
    function setSearchVesselTagTerm(
        searchVesselTagTerm: string[],
        isDefaultTagUser: boolean,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        ActionCreators.setSearchVesselTagTerm(
            searchVesselTagTerm,
            dispatch,
            state.incidentWidgetFilter,
            isDefaultTagUser,
        )
    }

    async function fetchUserPrefsIfExistForDashboard(isDefaultTagUser?: boolean): Promise<void> {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.requestFilter())
        const location = isActiveLocation(activeLocation) ? activeLocation.location : null

        try {
            const response = await REST.get(USER_PREFS_URL)
            if (isDefaultTagUser === true) {
                await handleDefaultTagUserCase(dispatch, response, location)
            } else {
                dispatch(
                    ActionCreators.receiveFilter(
                        formattedLocationsBlockSet([location]),
                        response.data.value.searchVesselTagTerm ??
                            DEFAULT_INCIDENTS_WIDGET_FILTER.searchVesselTagTerm,
                        response.data.value.incidentWidgetOnDashboard ??
                            IncidentWidgetType.LATEST_INCIDENT,
                        response.data.value.periodWidgetOnDashboard ?? '30d',
                    ),
                )
            }
        } catch {
            dispatch(
                ActionCreators.receiveFilter(
                    DEFAULT_INCIDENTS_WIDGET_FILTER.locations,
                    DEFAULT_INCIDENTS_WIDGET_FILTER.searchVesselTagTerm,
                    DEFAULT_INCIDENTS_WIDGET_FILTER.incidentWidgetOnDashboard,
                    DEFAULT_INCIDENTS_WIDGET_FILTER.periodWidgetOnDashboard,
                ),
            )
        }
    }

    const useExternalPeriod = (period?: DefaultPeriodOptions) => {
        useEffect(() => {
            dispatch({
                type: ActionType.SET_EXTERNAL_PERIOD,
                payload: period,
            })
        }, [period])
    }

    const useExternalIncidentWidget = (widget?: IncidentWidgetType) => {
        useEffect(() => {
            dispatch({
                type: ActionType.SET_EXTERNAL_INCIDENT_WIDGET,
                payload: widget,
            })
        }, [widget])
    }

    const loadedIncidentsDetails =
        state.loadingPopulatedDataState.incidentsDetails === LoadingState.Loaded
    const loadedIncidentsCounts =
        state.loadingPopulatedDataState.incidentsCounts === LoadingState.Loaded
    const loadedIncidentsAssignments =
        state.loadingPopulatedDataState.incidentsAssignments === LoadingState.Loaded
    const loadedIncidentsTrends =
        state.loadingPopulatedDataState.incidentsTrends === LoadingState.Loaded
    const loadedIncidentsByAvgPerformance =
        state.loadingPopulatedDataState.incidentsAvgPerformance === LoadingState.Loaded
    const loadedIncidentsByVesselPerformance =
        state.loadingPopulatedDataState.incidentsVesselsPerformance === LoadingState.Loaded

    const selectedIncidentWidget =
        state.incidentWidgetFilter.externalIncidentWidget ??
        state.incidentWidgetFilter.incidentWidgetOnDashboard
    const periodWidgetOnDashboard =
        state.incidentWidgetFilter.externalPeriod ??
        state.incidentWidgetFilter.periodWidgetOnDashboard
    const disabledPeriodWidget =
        !!state.incidentWidgetFilter.externalPeriod ||
        state.incidentWidgetFilter.incidentWidgetOnDashboard ===
            IncidentWidgetType.LATEST_INCIDENT ||
        state.incidentWidgetFilter.incidentWidgetOnDashboard ===
            IncidentWidgetType.RAISED_INCIDENTS_TRENDS ||
        state.incidentWidgetFilter.incidentWidgetOnDashboard ===
            IncidentWidgetType.INCIDENT_RESPONSE_PERFORMANCE
    const newIncidents = state.latestIncidentOverview.newIncidentsCount

    const openIncidents = state.latestIncidentOverview.openIncidentsCount

    const latestIncidentResponse = state.latestIncidentOverview

    const averageIncdientsAge = state.latestIncidentOverview.averageIncidentAge

    const hasNewIncidents = state.latestIncidentOverview.newIncidentsCount !== 0
    const model = new WidgetOutputModel(
        latestIncidentResponse.latestIncident,
        locationMap,
        activeLocation,
        nodes,
    )
    return {
        loadedIncidentsDetails: loadedIncidentsDetails,
        loadedIncidentsCounts: loadedIncidentsCounts,
        loadedIncidentsAssignments: loadedIncidentsAssignments,
        loadedIncidentsTrends: loadedIncidentsTrends,
        loadedIncidentsByAvgPerformance: loadedIncidentsByAvgPerformance,
        loadedIncidentsByVesselPerformance: loadedIncidentsByVesselPerformance,
        totalNumberOfItemsByStatus: state.filteredDataCounts?.countsByStatus,
        totalNumberOfItemsByTypes: transformTypesCountsToArray(
            state.filteredDataCounts?.countsByType,
        ),
        totalNumberOfItemsBySeverity: state.filteredDataCounts?.countsBySeverity,
        assignementsCounts: state.filteredAssignementsCounts,
        incidentsTrendsBySeverity: state.incidentsTrends,
        incidentsByAvgPerformance: state.incidentsAvgPerformance,
        incidentsByVesselPerformance: state.incidentsVesselsPerformance,
        setWidgetOnDashboardData,
        setPeriodWidgetOnDashboard,
        displayIncidentWidgetOnDashboard,
        selectedIncidentWidget,
        periodWidgetOnDashboard,
        disabledPeriodWidget,
        fetchUserPrefsIfExistForDashboard,
        newIncidents,
        openIncidents,
        latestIncidentResponse: model,
        averageIncdientsAge,
        hasNewIncidents,
        loadingFilter: state.loadingFilterState,
        noDataComponent: !latestIncidentResponse.latestIncident,
        setSearchVesselTagTerm,
        registerIncidentsOverviewQuery,
        useExternalPeriod,
        useExternalIncidentWidget,
    }
}

function getFormattedFilteredVessels(
    allLocations: Location[],
    filteredVessels: string[],
): string[] | undefined {
    return filteredVessels.length === allLocations.length ? undefined : filteredVessels
}

function getDataForDashboard(
    dispatch: Dispatch<AllActions>,
    periodWidgetOnDashboard: DefaultPeriodOptions,
    locations: Set<LocationIdType> | undefined,
    searchVesselTagTerm: string[],
    allLocations: Location[],
    incidentsWidgetOnDashboard: IncidentWidgetType,
): void {
    const filteredVessels = filteredVesselIds(allLocations, locations, searchVesselTagTerm ?? [])
    const now = new Date()
    let fromDateStr: string | null = null

    switch (periodWidgetOnDashboard) {
        case '1d':
            fromDateStr = new Date(now.getTime() - 24 * 60 * 60 * 1000).toISOString()
            break
        case '7d':
            fromDateStr = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000).toISOString()
            break
        case '30d':
            fromDateStr = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000).toISOString()
            break
        case '90d':
            fromDateStr = new Date(now.getTime() - 90 * 24 * 60 * 60 * 1000).toISOString()
            break
        default:
            fromDateStr = null
            break
    }

    switch (incidentsWidgetOnDashboard) {
        case IncidentWidgetType.CURRENT_ASSIGNMENT:
            const incidentsAssignmentsPromise = REST.post(
                `${INCIDENTS_ENDPOINT}/find/assignmentStatistics`,
                {
                    createdFrom: fromDateStr,
                    locations: getFormattedFilteredVessels(allLocations, filteredVessels),
                },
            )

            incidentsAssignmentsPromise
                .then((incidentsAssignments) =>
                    dispatch(
                        ActionCreators.receivedRequestedAssignmentsData(
                            incidentsAssignments.data as IncidentAssignmentsCounts,
                        ),
                    ),
                )
                .catch(() => {
                    dispatch(
                        ActionCreators.receivedRequestedAssignmentsData(DEFAULT_ASSIGNMENTS_COUNTS),
                    )
                })
            break
        case IncidentWidgetType.RAISED_INCIDENTS_TRENDS:
            const incidentsTrendsPromise = REST.post(`${INCIDENTS_ENDPOINT}/getIncidentTrends`, {
                locations: getFormattedFilteredVessels(allLocations, filteredVessels),
            })

            incidentsTrendsPromise
                .then((incidentsTrends) =>
                    dispatch(
                        ActionCreators.receivedRequestedTrendsData(
                            incidentsTrends.data as IncidentTrendsType[],
                        ),
                    ),
                )
                .catch(() => {
                    dispatch(ActionCreators.receivedRequestedTrendsData([] as IncidentTrendsType[]))
                })
            break
        case IncidentWidgetType.VESSELS_RESPONSE_PERFORMANCE:
            const incidentsVesselsPerformancePromise = REST.post(
                `${INCIDENTS_ENDPOINT}/vesselPerformance/summary`,
                {
                    createdFrom: fromDateStr,
                    locations: getFormattedFilteredVessels(allLocations, filteredVessels),
                },
            )

            incidentsVesselsPerformancePromise
                .then((incidentsVesselsPerformance) =>
                    dispatch(
                        ActionCreators.receivedRequestedVesselsPerformanceData(
                            incidentsVesselsPerformance.data as IncidentVesselsPerformanceType,
                        ),
                    ),
                )
                .catch(() => {
                    dispatch(
                        ActionCreators.receivedRequestedVesselsPerformanceData(
                            DEFAULT_VESSELS_PERFORMANCE_TYPE,
                        ),
                    )
                })
            break
        case IncidentWidgetType.INCIDENT_RESPONSE_PERFORMANCE:
            const incidentsAvgPerformancePromise = REST.post(
                `${INCIDENTS_ENDPOINT}/performance/summary`,
                {
                    locations: getFormattedFilteredVessels(allLocations, filteredVessels),
                },
            )

            incidentsAvgPerformancePromise
                .then((incidentsAvgPerformance) =>
                    dispatch(
                        ActionCreators.receivedRequestedAvgPerformanceData(
                            incidentsAvgPerformance.data as IncidentAvgPerformanceType[],
                        ),
                    ),
                )
                .catch(() => {
                    dispatch(
                        ActionCreators.receivedRequestedAvgPerformanceData(
                            [] as IncidentAvgPerformanceType[],
                        ),
                    )
                })
            break
        case IncidentWidgetType.CURRENT_STATUS:
        case IncidentWidgetType.SEVERITY_INCIDENTS:
        case IncidentWidgetType.TYPES_INCIDENTS:
        case IncidentWidgetType.INCIDENT_CLOSURE_RATE:
            const incidentsCountsPromise = REST.post(`${INCIDENTS_ENDPOINT}/getCounts`, {
                createdFrom: fromDateStr,
                locations: getFormattedFilteredVessels(allLocations, filteredVessels),
            })
            incidentsCountsPromise
                .then((incidentsCounts) =>
                    dispatch(
                        ActionCreators.receivedRequestedCountsData(
                            incidentsCounts.data as IncidentCountsType,
                        ),
                    ),
                )
                .catch(() => {
                    dispatch(ActionCreators.receivedRequestedCountsData(DEFAULT_INCIDENTS_COUNTS))
                })

            break
        default:
            break
    }
}

const handleDefaultTagUserCase = async (
    dispatch: Dispatch<AllActions>,
    response: AxiosResponse,
    location: string | null,
) => {
    try {
        const res = await REST.get(`${USER_PREFS_URL}.default_tag`)
        dispatch(
            ActionCreators.receiveFilter(
                formattedLocationsBlockSet([location]),
                res.data.value.default_tag ?? DEFAULT_INCIDENTS_WIDGET_FILTER.searchVesselTagTerm,
                response.data.value.incidentWidgetOnDashboard ?? IncidentWidgetType.LATEST_INCIDENT,
                response.data.value.periodWidgetOnDashboard ?? '30d',
            ),
        )
    } catch {
        dispatch(
            ActionCreators.receiveFilter(
                DEFAULT_INCIDENTS_WIDGET_FILTER.locations,
                response.data.value.searchVesselTagTerm ??
                    DEFAULT_INCIDENTS_WIDGET_FILTER.searchVesselTagTerm,
                response.data.value.incidentWidgetOnDashboard ??
                    DEFAULT_INCIDENTS_WIDGET_FILTER.incidentWidgetOnDashboard,
                response.data.value.periodWidgetOnDashboard ??
                    DEFAULT_INCIDENTS_WIDGET_FILTER.periodWidgetOnDashboard,
            ),
        )
    }
}
