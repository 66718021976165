import ActionType from './action-type'
import * as Actions from './actions'
import * as uuid from 'uuid'
import {ThunkAction} from 'redux-thunk'

import {Api, QuerySpecification} from '../../../api/Api'
import {QueryCancellation} from '../../../websocket/Queries'
import AppState from '../../types/app-state'

const QUERY_SCHEDULE = '15s'

function SFMQuery(): QuerySpecification {
    return {
        path: 'latestSfmScores',
        localId: `${'latestSfmScores'}-${uuid.v4()}`,
        schedule: QUERY_SCHEDULE,
    }
}

export function registerSFMQuery(): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch, _, api: Api): QueryCancellation => {
        dispatch(requestSFM())

        const query: QuerySpecification = SFMQuery()

        api.newQuery(query, (data: {scores: {}}) => {
            dispatch(receiveSFM(data.scores))
        })

        return () => {
            api.cancelQuery({localId: query.localId})
        }
    }
}

function requestSFM(): Actions.RequestSFMAction {
    return {
        type: ActionType.REQUEST_SFM_SCORES,
    }
}

function receiveSFM(scores: {[guid: string]: number}): Actions.ReceiveSFMAction {
    return {
        type: ActionType.RECEIVE_SFM_SCORES,
        payload: scores,
    }
}
