import {MessageHandler} from './PathBasedMessageHandler'
import {default as Debug} from 'debug'

/**
 * A path to Query handling on the server.
 * Must be coordinated with eu.xword.nixer.webui.services.NixerWebUiModule.KEEPALIVE_PATH
 */
const KEEPALIVE_PATH = 'keepalive'

const KEEPALIVE_TIME_INTERVAL = 20000 // interval in ms, default is 20 seconds

const RECONNECT_FIRST_TRIES_COUNT = 10
const RECONNECT_TIME_FOR_FIRST_TRY = 500 // 0.5 second
const RECONNECT_TIME_FOR_FIRST_TRIES = 6000 // 6 seconds
const RECONNECT_TIME_LATER_TRIES = 60000 // 1 minute

const debug = Debug('WebSocket')

export class CommonWebSocket {
    private ws: WebSocket | undefined

    private lastTimeoutFunc?: number = undefined

    private reconnectionFails = 0

    private callbacksOnConnect: ((ev: Event) => {})[] | undefined

    private callbacksOnReconnect: ((ev: Event) => {})[] | undefined

    private callbacksOnClose: ((event: CloseEvent) => void)[] | undefined

    public constructor(
        public path: string,
        public handler: MessageHandler<{}>,
        public urlBase?: string,
        public disableKeepAlive?: boolean,
        public keepAliveInterval?: number,
        public disableReconnect?: boolean,
    ) {
        // if (!handler) {
        //     Debugging.reportProblem("CommonWebSocket ERROR missing required handler!");
        // }

        // keep alive interval can be set for debug/test purpose
        if (!keepAliveInterval) {
            this.keepAliveInterval = KEEPALIVE_TIME_INTERVAL
        }
    }

    public connect(
        callbacksOnConnect?: ((ev: Event) => {})[],
        callbacksOnReconnect?: ((ev: Event) => {})[],
        callbacksOnClose?: ((ev: CloseEvent) => void)[],
    ): CommonWebSocket {
        const protocol: string = window.location.protocol === 'http:' ? 'ws' : 'wss'

        const base: string = this.urlBase ? this.urlBase : `${protocol}://${window.location.host}`

        const url: string = base + this.path

        debug('Opening WebSocket url: ', url)

        this.ws = new WebSocket(url)

        this.callbacksOnConnect = callbacksOnConnect || []
        this.callbacksOnReconnect = callbacksOnReconnect || []
        this.callbacksOnClose = callbacksOnClose || []

        this.ws.onopen = (event: Event) => {
            debug(`Connection established, event: ${JSON.stringify(event)}`)
        }
        this.ws.onmessage = (event: MessageEvent) => {
            // Debugging.log("CommonWebSocket receiving: ", event.data);
            this.handler.accept(JSON.parse(event.data))
        }

        if (this.reconnectionFails > 0) {
            for (const callback of this.callbacksOnReconnect) {
                this.ws.addEventListener('open', callback)
            }
        } else {
            for (const callback of this.callbacksOnConnect) {
                this.ws.addEventListener('open', callback)
            }
        }

        if (!this.disableKeepAlive) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            this.ws.addEventListener('open', (_) => {
                this.send({path: KEEPALIVE_PATH}) // initial keep alive "ping"
            })
        }

        if (!this.disableReconnect) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            this.ws.addEventListener('open', (_) => {
                this.reconnectionFails = 0
            })
        }

        this.ws.onclose = (event: CloseEvent) => {
            if (this.lastTimeoutFunc) {
                clearTimeout(this.lastTimeoutFunc) // remove waiting "ping" if exists
            }

            if (this.disableReconnect) {
                return
            }

            debug(`CommonWebSocket closed, code: ${event.code}, reason: ${event.reason}`)

            if (this.callbacksOnClose) {
                for (const callback of this.callbacksOnClose) {
                    callback(event)
                }
            }

            // reconnect after 0,5 seconds for first fail
            // reconnect each 6 seconds for next 9 fails
            // or for each minute if more than 10 fails
            const reconnectTime: number =
                this.reconnectionFails <= RECONNECT_FIRST_TRIES_COUNT
                    ? this.reconnectionFails === 0
                        ? RECONNECT_TIME_FOR_FIRST_TRY
                        : RECONNECT_TIME_FOR_FIRST_TRIES
                    : RECONNECT_TIME_LATER_TRIES

            this.reconnectionFails++
            const connect: () => void = () => {
                this.connect(undefined, callbacksOnReconnect, callbacksOnClose)
            }
            debug(`Reconnect in ${reconnectTime}ms`)
            window.setTimeout(connect, reconnectTime)
        }

        this.ws.onerror = (event: Event) => {
            debug('CommonWebSocket error: ', event.type)
        }

        return this
    }

    public disconnect(): void {
        debug('Disconnecting from websocket')
        if (this.lastTimeoutFunc) {
            clearTimeout(this.lastTimeoutFunc)
        }
        this.disableReconnect = true
        this.ws?.close()
    }

    // --- implementation for Nixer.Sender

    public send(object: {}): void {
        const data: string = JSON.stringify(object)

        debug('CommonWebSocket sending: ', data)
        if (this.isOpen() && this.ws) {
            this.ws.send(data)
        } else {
            debug('Unable to send message to closed WebSocket')
        }

        if (!this.disableKeepAlive) {
            if (this.lastTimeoutFunc) {
                clearTimeout(this.lastTimeoutFunc) // remove waiting "ping" if exists
            }
            this.lastTimeoutFunc = window.setTimeout(() => {
                this.send({path: KEEPALIVE_PATH})
            }, this.keepAliveInterval) // keep alive "ping"
        }
    }

    private isOpen(): boolean {
        return this.ws != undefined && this.ws.readyState === this.ws.OPEN
    }
}

/*
Example of usage of CommonWebSocket with PathBasedMessageHandler:

export class ExampleMessageHandler implements MessageHandler<A> {
    public accept(message: A): void {
        console.log(message.a + " " + message.b);
    }
}

class A {
    constructor(public a: string, public b: number) {};
}

const socket: CommonWebSocket = new CommonWebSocket("/ws/example", new PathBasedMessageHandler({
    example: new ExampleMessageHandler()
}));

socket.connect();
*/
