import {useEffect} from 'react'
import {Helmet} from 'react-helmet'
import {ThemeProvider} from 'styled-components'
import {LightTheme} from '../theme/light-theme'
import {DarkTheme} from '../theme/dark-theme'
import {configSelector} from '../store/state/config/selectors'
import RoleChecker from '../components/RoleChecker'
import useTypedSelector from '../hooks/use-typed-selector'
import {logInStatusSelector} from '../store/state/session-data/selectors'
import {DimensionsProvider} from '../contexts/dimensions/dimensions-provider'
import {AuthenticatedRoutes} from './authenticated-routes'
import {UnauthenticatedRoutes} from './unauthenticated-routes'
import {useAuthenticatedSemaphore} from '../hooks/use-authenticated-semaphore'
import {LoginStatus} from '../store/state/session-data/state'
import {useDispatch} from 'react-redux'
import {loggingOut} from '../store/state/session-data/action-creators'
import {currentUserSelector} from '../store/state/current-user/selectors'
import {setCustomLogoFlag, setGfcFlag, setUserGuiding} from '../store/state/config/action-creators'
import {BrowserUtils, getTagName} from '../utils/Utils'
import {PageType} from '../components/vessel-filters/data-helper'
import {setSearchVesselTagTerm} from '../store/state/vessel-filter/action-creators'
import {vesselTagsSelector} from '../store/state/vessel-tags/selectors'
import {StrictMode} from '../store/state/users/state'
import {useFleetScoreOverTime} from '../pages/dashboard-v2/components/fleet-score-over-time/contexts/use-fleet-score-over-time'
import {useTagsComponent} from '../pages/dashboard-v2/components/tags-component/contexts/use-tags-component'
import {injectUserGuiding} from '../utils/inject-user-guiding'
import {UserGuidingConfig} from '../store/state/config/state'
import {useIncidentsWidget} from '../pages/dashboard-v2/components/incident-response/contexts/use-incidents-widget'

const App = (): JSX.Element => {
    const dispatch = useDispatch()
    const config = useTypedSelector(configSelector)
    const loginStatus = useTypedSelector(logInStatusSelector)
    const {authenticationSemaphoreStatus} = useAuthenticatedSemaphore()
    const {strictMode, assignedTag} = useTypedSelector(currentUserSelector)
    const existingTags = useTypedSelector(vesselTagsSelector)

    const setSearchVesselTagForTimeline = useFleetScoreOverTime().setSearchVesselTagTerm
    const setSearchVesselTagForTagCompare = useTagsComponent().setSearchVesselTagTerm
    const setSearchVesselTagForIncidentWidget = useIncidentsWidget().setSearchVesselTagTerm

    useEffect(() => {
        async function fetchConfigFlag() {
            try {
                const result = await fetch(
                    `${import.meta.env.BASE_URL}config/${import.meta.env.VITE_ALT_BACKEND ?? 'medulla-ui'}.json`,
                )
                const response = await result.json()
                dispatch(setGfcFlag(response.gfc))
                dispatch(setCustomLogoFlag(response.customLogo))
                const formattedEnableFlag =
                    response.userGuidingEnabled == undefined ? true : response.userGuidingEnabled
                const formattedUserGuidFlag =
                    response.userGuidingId == undefined ? '021118067ID' : response.userGuidingId
                const receivedUserresponse: UserGuidingConfig = {
                    userGuidingEnabled: formattedEnableFlag,
                    userGuidingId: formattedUserGuidFlag,
                }
                dispatch(setUserGuiding(receivedUserresponse))
                await injectUserGuiding(receivedUserresponse)
                window.userGuiding.identify(formattedUserGuidFlag)
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error)
            }
        }
        fetchConfigFlag()
    }, [dispatch])

    if (config.gfcFlag) {
        import('../scss/font-gfc.scss')
    } else {
        import('../scss/font.scss')
    }

    const favicon: HTMLLinkElement | null = document.querySelector('link#faviconRel')
    if (favicon) {
        if (!BrowserUtils.isBrowserDarkMode()) {
            favicon.href = `${import.meta.env.BASE_URL}faviconLight.ico`
        }
    }

    const title: string = config.tenantDisplayName
        ? `${config.tenantDisplayName} - Medulla`
        : 'Medulla'

    const darkMode = false

    useEffect(() => {
        if (authenticationSemaphoreStatus) {
            // do nothing for now
        } else {
            if (
                loginStatus == null ||
                loginStatus == undefined ||
                loginStatus === LoginStatus.LOGGED_IN
            ) {
                dispatch(loggingOut())
            }
        }
    }, [authenticationSemaphoreStatus, dispatch, loginStatus])

    useEffect(() => {
        if (loginStatus === LoginStatus.LOGGED_IN) {
            const assignedTagName = getTagName(existingTags, assignedTag)
            dispatch(
                setSearchVesselTagTerm(
                    assignedTagName ? [assignedTagName] : [],
                    PageType.INCIDENTS,
                    strictMode === StrictMode.DefaultVisibility,
                ),
            )
            dispatch(
                setSearchVesselTagTerm(
                    assignedTagName ? [assignedTagName] : [],
                    PageType.MY_VESSELS,
                    strictMode === StrictMode.DefaultVisibility,
                ),
            )
            dispatch(
                setSearchVesselTagTerm(
                    assignedTagName ? [assignedTagName] : [],
                    PageType.REPORTS,
                    strictMode === StrictMode.DefaultVisibility,
                ),
            )
            dispatch(
                setSearchVesselTagTerm(
                    assignedTagName ? [assignedTagName] : [],
                    PageType.NETWORK_ASSETS,
                    strictMode === StrictMode.DefaultVisibility,
                ),
            )
            dispatch(
                setSearchVesselTagTerm(
                    assignedTagName ? [assignedTagName] : [],
                    PageType.USB_DEVICES,
                    strictMode === StrictMode.DefaultVisibility,
                ),
            )
            dispatch(
                setSearchVesselTagTerm(
                    assignedTagName ? [assignedTagName] : [],
                    PageType.SOFTWARE_INVENTORY,
                    strictMode === StrictMode.DefaultVisibility,
                ),
            )

            dispatch(
                setSearchVesselTagTerm(
                    assignedTagName ? [assignedTagName] : [],
                    PageType.METRICS,
                    strictMode === StrictMode.DefaultVisibility,
                ),
            )

            dispatch(
                setSearchVesselTagTerm(
                    assignedTagName ? [assignedTagName] : [],
                    PageType.VESSEL_MANAGEMENT,
                    strictMode === StrictMode.DefaultVisibility,
                ),
            )
            dispatch(
                setSearchVesselTagTerm(
                    assignedTagName ? [assignedTagName] : [],
                    PageType.INCIDENTS_REPORT,
                    strictMode === StrictMode.DefaultVisibility,
                ),
            )
            setSearchVesselTagForTimeline(existingTags.find((tag) => tag.identity === assignedTag))
            setSearchVesselTagForTagCompare(assignedTagName ? [assignedTagName] : [])
            setSearchVesselTagForIncidentWidget(
                assignedTagName ? [assignedTagName] : [],
                strictMode === StrictMode.DefaultVisibility,
            )
        }
    }, [loginStatus, strictMode, assignedTag, existingTags])

    return (
        <DimensionsProvider>
            <ThemeProvider theme={darkMode ? DarkTheme : LightTheme}>
                <RoleChecker rolesAllowed={['DEVELOPER', 'THREAT_ANALYST']}>
                    <Helmet>
                        <title>{title}</title>
                    </Helmet>
                </RoleChecker>

                {loginStatus === LoginStatus.LOGGED_IN ? (
                    <AuthenticatedRoutes />
                ) : (
                    <UnauthenticatedRoutes />
                )}
            </ThemeProvider>
        </DimensionsProvider>
    )
}

export default App
