export interface MessageHandler<T> {
    accept(message: T): void
}

export interface PathMapping {
    [path: string]: MessageHandler<{path: string}>
}

interface MessageWithPath {
    path?: string
}

export class PathBasedMessageHandler implements MessageHandler<MessageWithPath> {
    public constructor(public mapping: PathMapping) {
        this.mapping = mapping
    }

    public accept(message: MessageWithPath): void {
        if (!message.path) {
            // Debugging.reportProblem('Missing path in message: ', message);
            return
        }

        const pathSplitLimit = 2
        const basePath: string = message.path.split('/', pathSplitLimit)[0]

        message.path = message.path.substring(basePath.length + 1)
        if (message.path === '') {
            delete message.path
        }

        const handler: MessageHandler<{}> = this.mapping[basePath]

        if (!handler) {
            // Debugging.reportProblem('Missing handler for: ', basePath);
            return
        }

        handler.accept(message)
    }
}
